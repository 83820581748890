import { useSubscriptionEnabled } from 'hooks/useSubscriptionEnabled';
import { useSelector } from 'react-redux';
import { ReduxState } from 'ducks';

export const useCustomerLedgerEnabled = () => {
  const subscriptionEnabled = useSubscriptionEnabled('feature-customer-ledger');

  const apiSettings = useSelector((state: ReduxState) => state.server.settings.all);

  const settingEnabled = apiSettings?.customer_ledger_settings?.is_enabled ?? false;

  return subscriptionEnabled && settingEnabled;
};
