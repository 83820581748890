import { useSelector } from 'react-redux';
import { ReduxState } from 'ducks';

export const useSubscriptionEnabled = (feature: string): boolean => {
  const enabled = useSelector(
    (state: ReduxState) =>
      state.server.settings.all?.subscriptions?.some(
        (subscription: any) => subscription.key === feature && subscription.status === 'ACTIVE'
      ) ?? false
  );

  return enabled;
};
